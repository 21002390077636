<template>
  <div class="Zhusu">
    <div class="Guize pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>Accommodation Reservation</span> Service</h3>
        </div>
        <p class="_txt">Qualified applicants can reserve university accommodations through ZHUXUE Accommodation Booking Service System. This service mainly provides options of student dormitories. You can book your accommodation after you login your ZHUXUE account. ZHUXUE will also direct you to choose nearby hotels if you are not interested in living on campus.</p>
        <p class="_txt">Non-ZHUXUE users can only book accommodation of Universities in Beijing and will be charged $100 for using this service.</p>
        <p class="_txt">Yes, this service is FREE for students applying through ZHUXUE!</p>
        <p class="_tit">Tips</p>
        <p class="_titp">In case of insufficiency, we advise you to book accommodation once you receive the admission notice.</p>
        <p class="_titp">Some universities do not accept room reservation and adopts the rule of “First come, first served”. So ZHUXUE advises you to come early during the registration period.</p>
        <div class="tit">
          Reservation Procedure
        </div>
        <div class="buzhou">
          <el-timeline>
            <el-timeline-item timestamp="Step 1"
                              placement="top">
              <el-card>
                <h4>Learning accommodation information</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 2"
                              placement="top">
              <el-card>
                <h4>Completing application form online</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 3"
                              placement="top">
              <el-card>
                <h4>Paying service fee of $100 (only for non-ZHUXUE users)</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="Step 4 "
                              placement="top">
              <el-card>
                <h4>Receiving reservation notice</h4>
                <p>ps: If you need accommodation reservation service, click <span @click="onShowVioForm()">Apply for accommodation</span> we will contact you as soon as possible.</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="tit">
          Service Standard
        </div>
        <p>• Dorms will be reserved one working day after the form is submitted</p>
        <p>• The student will be informed by email once we receive the reservation notice</p>
        <p>• Reconfirmation will be made with the university before the student departs</p>
        <div class="tit">
          FAQ
        </div>
        <div class="faq">
          <el-collapse accordion>
            <el-collapse-item v-for="(item,index) in faq"
                              :key="index">
              <template #title>
                <h3 class="faqtit">
                  <div class="imgbox"><img src="@/assets/qu.png"
                         alt=""></div> {{item.ft}}
                </h3>
              </template>
              <div class="asCont">
                <div class="imgbox">
                  <img src="@/assets/as.png"
                       alt="">
                </div>
                <div class="txt">
                  {{item.fa}}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <ComForm ref='vioForm'
             :typeId='8'
             formTitle="Apply for accommodation"></ComForm>
  </div>
</template>

<script>
import ComForm from "@/components/ComForm.vue";
import { ref } from "vue";
export default {
  name: 'Zhusu',
  components: {
    ComForm
  },
  data () {
    return {
      faq: [{
        ft: 'Under what conditions will my reservation fail?',
        fa: 'The following two conditions might result in unsuccessful room reservation: Your university provides no on-campus dormitory; Insufficiency of dorms.'
      },
      {
        ft: 'Can I live off campus?',
        fa: 'It is allowed by many Chinese universities.'
      },
      {
        ft: 'Can I ask my family to live with me on campus?',
        fa: 'It is allowed by certain Chinese universities.'
      },
      {
        ft: 'I ticked Single Room in my application form, why a double room reservation is shown instead?',
        fa: "Due to insufficiency, your university cannot meet all students' requirements. If you agree to accept room transfer, ZHUXUE will book double room to guarantee your accommodation on campus."
      },
      {
        ft: 'Will the accommodation fee be adjusted when I arrive?',
        fa: 'Information on ZHUXUE and in other relevant documents is all gathered from the university, which is for your reference only. It is subject to change during the registration..'
      },
      {
        ft: 'Can I check in before the registration period?',
        fa: 'Some universities allow students to come before the registration date.'
      },
      {
        ft: 'What should I do if I miss the registration?',
        fa: 'Universities require students to register on time. If you were not on time, you should make immediate contact with ZHUXUE or your university to ask for permission. Otherwise, you will be deemed as giving up enrollment.'
      },
      {
        ft: "I didn't book accommodation. What should I do if there is no room when I arrive?",
        fa: 'ZHUXUE advises students to book accommodation beforehand. If there is no room after your arrival, please contact  the university or ZHUXUE for help. ZHUXUE can find you accommodation information off campus.'
      }
      ]
    }
  },
  setup () {
    const vioForm = ref()
    const onShowVioForm = () => {
      vioForm.value.onShowVio()
    }
    return {
      vioForm,
      onShowVioForm
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
._txt {
  font-size: 14px;
  text-indent: 2em;
  line-height: 2;
}

.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
.Guize {
  p {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 10px;
    &.b {
      text-indent: 0;
      font-weight: bold;
      margin-top: 15px;
    }
  }
  .buzhou {
    h4 {
      margin: 0;
      font-size: 16px;
    }
    a {
      color: #0c3879;
    }
    p {
      text-indent: 0;
      font-size: 14px;
      color: #454545;
      span {
        color: #0c3879;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .faq {
    .faqtit {
      display: flex;
      .imgbox {
        width: 30px;
        margin-right: 10px;
      }
    }
    .asCont {
      display: flex;
      .imgbox {
        width: 30px;
      }
      .txt {
        padding-left: 10px;
        line-height: 30px;
      }
    }
  }
}
._tit {
  color: #ff7e00;
  font-weight: bold;
}
._titp {
  font-size: 14px !important;
  color: #545454;
  line-height: 1.5;
  margin: 0 !important;
}
</style>